import React from "react";

export default function Navigation() {
    return (
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark px-3 fixed-top">
            <h1 class="navbar-brand ml-3" href="#"><span class="aqua">Adam</span>Dill</h1>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a href="#about" class="nav-link">About</a>
                    </li>
                    <li class="nav-item">
                        <a href="#experience" class="nav-link">Experience</a>
                    </li>
                    <li class="nav-item">
                        <a href="#toolbox" class="nav-link">Toolbox</a>
                    </li>
                    <li class="nav-item">
                        <a href="#education" class="nav-link">Education</a>
                    </li>
                    <li class="nav-item">
                        <a href="#contact" class="nav-link">Contact</a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}
